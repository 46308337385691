import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { PageContextPropsType } from '../../../i18n/i18n'
import LayoutSimpleFooter from '../../components/LayoutSimpleFooter'
import SEO from '../../components/SEO'
import FormEbook from '../../components/FormEbook'
import classNames from 'classnames/bind'
import * as EbookPageStyles from '../../styles/EbookPage.css'

const cx = classNames.bind(EbookPageStyles)

export default function EbookPage({ pageContext }: PageContextPropsType) {
  const reCaptchaKey = process.env.GATSBY_RECAPTCHA_CLIENT_SECRET_KEY || ''
  return (
    <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
      <LayoutSimpleFooter pageContext={pageContext}>
        <SEO
          title="【D2C・コスメ業界】サイト改善事例20選 | KARTE Blocks"
          description="KARTE Blocksを活用したD2C・コスメ業界のサイト改善事例をまとめた資料をダウンロードいただけます。"
          path="/ebook/cosme/"
        />

        <main className={cx('Outer')}>
          <div className={cx('Container')}>
            <section className={cx('Content')}>
              <h1 className={cx('Headline')}>
              【D2C・コスメ業界】
                <br />
                サイト改善事例20選
              </h1>

              <StaticImage src="../../images/ebook/cosme-thumbnail.png" alt="資料の表紙" />

              <section className={cx('Details')}>
                <h2 className={cx('Details_Headline')}>資料の内容</h2>
                <p className={cx('Details_Summary')}>
                本資料では、D2C・コスメ業界におけるよくあるお悩みから、KARTE Blocksを活用した20の最新事例、お客様の声を記載しております。
                </p>
                <ol className={cx('Details_Outline')}>
                  <li className={cx('Details_Item')}>D2C・コスメ業界のよくあるお悩み</li>
                  <li className={cx('Details_Item')}>KARTE Blocksについて</li>
                  <li className={cx('Details_Item')}>KARTE Blocksを活用したサイト改善事例 20選</li>
                  <li className={cx('Details_Item')}>お客様の声、導入後の変化</li>
                  <li className={cx('Details_Item')}>プラン・サポート</li>
                </ol>
                <div className={cx('Details_List_Wrapper')}>
                  <p className={cx('Details_List_Head')}>こんな方におすすめ！</p>
                  <ul className={cx('Details_List_Items')}>
                    <li className={cx('Details_List_Item')}>ECサイトの売上を伸ばしたい</li>
                    <li className={cx('Details_List_Item')}>D2C・コスメ業界での他社の取り組み事例・鉄板施策を知りたい</li>
                  </ul>
                </div>
              </section>
            </section>

            <FormEbook actionUrl="https://deal.karte.io/l/819613/2023-11-14/bv6dm" actionName="blockskarteio/ebook/cosme/" />
          </div>
        </main>
      </LayoutSimpleFooter>
    </GoogleReCaptchaProvider>
  )
}
